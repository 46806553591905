<script setup lang="ts">
import type { SbBlokData } from '@storyblok/js';

interface Props {
    blok: ArticlePage001;
    breadcrumbLinks: BreadcrumbLink[];
    firstPublishedAt: string;
    publishedAt: string;
    seoData: SeoDataInterface;
    globalArticleCopy: GlobalArticleCopyInterface;
    h2List: ArticleH2BlokInterface[];
}
defineProps<Props>();

const stickySidebar = useTemplateRef('stickySidebar');
const stickyActive = ref(false);

onMounted(() => {
    const stickyObserver = new IntersectionObserver(
        ([entry]) => {
            stickyActive.value = !entry.isIntersecting;
        },
        {
            /* account for the height of the sticky nav */
            rootMargin: '-60px 0px 0px 0px',
            threshold: [1],
        },
    );
    stickyObserver.observe(stickySidebar.value as Element);
});
</script>

<template>
    <div
        v-editable="blok"
        class="container">
        <es-row class="justify-content-center mt-100">
            <es-col
                cols="12"
                xl="10">
                <article-breadcrumb-list
                    :blok="blok.articleBreadcrumbList?.[0]"
                    :breadcrumb-links="breadcrumbLinks" />
                <storyblok-component
                    v-for="childBlok in blok.articleEeat"
                    :key="childBlok._uid"
                    :blok="childBlok as unknown as SbBlokData"
                    :first-published-at="firstPublishedAt"
                    :published-at="publishedAt"
                    :seo-data="seoData"
                    :global-article-copy="globalArticleCopy" />
            </es-col>
        </es-row>
        <es-row class="justify-content-center">
            <es-col
                class="mb-100"
                cols="12"
                lg="4"
                xl="3">
                <!-- sidebar becomes sticky on desktop upon scrolling down -->
                <div ref="stickySidebar" />
                <div class="ArticlePage001-sidebar">
                    <!-- Why trust EnergySage section -->
                    <div class="mb-200 mb-lg-0">
                        <collapse
                            v-if="blok.whyTrustEnergySage?.content?.heading"
                            :heading="blok.whyTrustEnergySage.content.heading">
                            <template #content>
                                <storyblok-component
                                    v-if="blok.whyTrustEnergySage?.content?.body?.[0]"
                                    :blok="blok.whyTrustEnergySage.content?.body[0] as unknown as SbBlokData" />
                            </template>
                        </collapse>
                    </div>

                    <!-- table of contents -->
                    <div class="ArticlePage001-toc mb-0">
                        <storyblok-component
                            v-if="blok.articleTableOfContents && blok.articleTableOfContents[0] && h2List.length > 0"
                            :blok="blok.articleTableOfContents[0] as unknown as SbBlokData"
                            :h2-list="h2List"
                            :sticky-active="stickyActive" />
                    </div>
                </div>
            </es-col>
            <es-col
                cols="12"
                lg="8"
                xl="7">
                <storyblok-component
                    v-if="blok.articleImage && blok.articleImage[0]"
                    :eager-load="true"
                    :blok="blok.articleImage[0] as unknown as SbBlokData" />
                <storyblok-component
                    v-for="childBlok in blok.body"
                    :key="childBlok._uid"
                    :blok="childBlok as unknown as SbBlokData"
                    :parent-blok="blok"
                    :seo-data="seoData"
                    :global-article-copy="globalArticleCopy" />
            </es-col>
        </es-row>
        <es-row class="justify-content-center mt-100">
            <es-col
                cols="12"
                xl="10">
                <article-user-actions
                    class="mb-200"
                    :article="blok"
                    :seo-data="seoData"
                    :global-article-copy="globalArticleCopy" />
                <storyblok-component
                    v-for="childBlok in blok.articleRecommended"
                    :key="childBlok._uid"
                    :blok="childBlok as unknown as SbBlokData"
                    :global-article-copy="globalArticleCopy" />
                <cross-sell-cards
                    v-if="blok.crossSellCards?.length"
                    class="mb-200"
                    :heading="globalArticleCopy.crossSellHeading"
                    :cards="blok.crossSellCards" />
            </es-col>
        </es-row>
        <storyblok-component
            v-if="blok.fixedUpperBannerCta"
            :show="stickyActive"
            :blok="blok.fixedUpperBannerCta.content as unknown as SbBlokData" />
    </div>
</template>

<style lang="scss" scoped>
@use '@energysage/es-ds-styles/scss/mixins/breakpoints';
@use '~/assets/scss/constants';

.ArticlePage001 {
    &-sidebar {
        // offset to account for sticky navbar
        @include breakpoints.media-breakpoint-up(lg) {
            position: sticky;
            top: constants.$sticky-navbar-height;
        }

        :deep(.expanded) {
            padding-bottom: 0 !important;
        }
    }

    &-toc {
        :deep(.top-divider) {
            padding-top: 0 !important;
        }
    }
}
</style>
